import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';
import { ElementType } from 'react';
import { SimpleResponse } from './lib/api/opensearch/client';
import { address } from './lib/api';

export type EventProps = {
  name: string;
  subheading?: string;
  slug: string;
  displayCurrency: Currency;
  logo: string;
  sponsorIds?: string;
  donateDirectlyUrl: string;
  sponsorUnfilteredDonationsLeaderboard: boolean;
  topTeamsRaisingLeaderboard: boolean;
  backgroundColor?: string;
  eventStartsAt: number;
  eventEndsAt?: number;
  leaderboardsStartAt: number;
  leaderboardsEndAt: number;
  showCountdownTimer: boolean;
  fundraiseForUsUrl?: string;
  fundraiseForUsButtonBackgroundColor?: string;
  fundraiseForUsButtonTextColor?: string;
  instructions?: EventInstruction[];
  listOfWinners?: EventWinner[];
  eventStats?: EventStat[];
  testimonials?: EventTestimonial[];
};

type EventInstruction = {
  image: string;
  title: string;
  body: string;
};

type EventWinner = {
  name: string;
};

type EventStat = {
  key: string;
  value: string;
};

type EventTestimonial = {
  description: string;
  body: string;
  person: string;
  organization: string;
  fundsRaised: number;
  currency: Currency;
  location: string;
  image: string;
};

export type CompetitionOverview = {
  totalDonatedAmount: number;
  totalDonorCountries: number;
  totalDonors: number;
  totalProjects: number;
  totalSponsors: number;
};

export type LeaderboardOptions = {
  seasonType: SeasonType;
  locationType: LocationType;
  type: LeaderboardType;
  startDate: string;
  endDate: string;
  currencyCode: string;
  sponsorIds?: string[];
  limit: number;
  offset: number;
  prizeAmounts?: PrizeAmount[];
};

export type PrizeAmount = {
  position: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  amount: number;
};

export type LeaderboardStyle = 'events' | 'seasonal';

export enum LeaderboardTypeEnum {
  TOP_TEAMS_RAISING = 'top-teams-raising',
  SPONSOR_UNFILTERED_DONATIONS = 'sponsor-unfiltered-donations',
  CHALLENGE_DONATIONS = 'challenge-donations',
  /**
   * Most Supporters
   */
  DEFAULT_DONORS = 'default-donors',
  /**
   * Most Raised
   */
  DEFAULT_DONATIONS = 'default-donations',
  MOST_REFERRALS = 'most-referrals',
  MOST_RAISED = 'most-raised',
}

export enum ActiveLeaderboardType {
  referrals = 'referrals',
  raised = 'most-raised',
  supporters = 'supporters',
}

export enum CompetitionRoute {
  EventLeaderboard = 'competition-get-event-leaderboard',
  SeasonalLeaderboard = 'competition-get-seasonal-leaderboard',
  MostReferrals = 'competition-get-most-referrals',
}

export enum CompetitionType {
  FundsRaised = 'FUNDS_RAISED',
  MostSupporters = 'MOST_SUPPORTERS',
  MostReferrals = 'MOST_REFERRALS',
}

export enum SeasonType {
  Ramadan = 'RAMADAN',
  DailyGivers = 'DAILY_GIVERS',
  FridayGivers = 'FRIDAY_GIVERS',
  DhulHijjah = 'DHUL_HIJJAH',
  GivingTuesday = 'GIVING_TUESDAY',
  RasulWeek = 'RASUL_WEEK',
}

export enum GivingPlan {
  DailyGivers = 1,
  DhulHijjah = 2,
  FridayGivers = 3,
  Ramadan30Nights = 4,
  Ramadan10Nights = 5,
  RasulWeek = 6,
}

export enum LocationType {
  Apac = 'APAC',
  Global = 'GLOBAL',
}

export type Currency =
  | 'USD'
  | 'GBP'
  | 'AUD'
  | 'CAD'
  | 'NZD'
  | 'MYR'
  | 'SGD'
  | 'EUR';

export enum CurrencyCodeToSymbol {
  USD = '$',
  GBP = '£',
  AUD = '$',
  CAD = '$',
  NZD = '$',
  MYR = 'RM',
  SGD = '$',
  EUR = '€',
}

export enum CurrencyFlags {
  USD = '🇺🇸',
  GBP = '🇬🇧',
  AUD = '🇦🇺',
  CAD = '🇨🇦',
  NZD = '🇳🇿',
  MYR = '🇲🇾',
  SGD = '🇸🇬',
  EUR = '🇪🇺',
}

export type CurrencyObject = {
  currencySymbol: string;
  currencyCode: Currency;
  amount: number;
  /** amount to locale, e.g. 15000 => $15,000 */
  amountString: string;
};

export type CurrencyFormatProps = Omit<
  CurrencyObject,
  'amountString' | 'currencySymbol'
> & {
  updateWithExchangeRate?: boolean;

  className?: string;
  /**
   * The element to render the currency number as
   * @example h1, p, span
   */
  element: ElementType;
  withDecimals?: boolean;
  /**
   * If `withDecimals` is `false`, you can set this to true to show trailing decimals
   * @example $25.00, £25.00 etc
   * @default false
   */
  showTrailingDecimals?: boolean;
  /**
   * Whether or not you would like to only show the first x digits of the number
   * @example $13m, £250k etc
   * @default false
   */
  shortenNumber?: boolean;
  /**
   * Whether or not you would like to append the currency code to the end of the number
   * @example $13m USD, £250k GBP etc
   */
  appendCurrencyCode?: boolean;
  /**
   * Whether or not you would like to append fixed decimals to the number
   * @example $12.0, £250.00 etc
   */
  decimals?: number;
  /**
   * Whether or not you would like to return the raw text without any HTML elements
   */
  rawText?: boolean;
};

export type SupportedCurrencies = {
  success: boolean;
  message?: string;
  result: string[];
};

export type CurrencyExchange = {
  from: Currency;
  to: Currency;
};

export type ExchangeRateResponse = {
  rate: number;
  from: Currency;
  to: Currency;
  createdAt: DateTime;
};

export type ExchangeRateSubjectProps = {
  userSelectedCurrency: Currency | null;
  rates: ExchangeRateResponse[];
  updatedAt: Moment;
};

export type CompetitionStats = {
  totalDonatedAmount: number;
  totalDonorCountries: number;
  totalDonors: number;
  totalProjects: number;
  totalSponsors: number;
};

export type LeaderboardParticipant = {
  projectId: string;
  donatedAmount: number;
  currencyCode: string;
  donors: number;
  donatedInCurrency: number;
  donationRank: 11;
  name: string;
  url: string;
  thumbnail: string;
  donationPercent: number;
  donorRank: number;
  donorsPercent: number;
  listType?: string;
  slug?: string;
  sponsorId?: number;
  sponsorName?: string;
};

export type ReferralsParticipant = {
  teamName: string;
  teamSlug: string;
  userId: number;
  projectId: number;
  projectName: string;
  projectUrl: string;
  projectImage: string;
  challengeId: number;
  counts: number;
  rank: number;
};

export type InternalReceipts = {
  campaignName: string;
  donationAmount: number;
  campaignURL: string;
  donationDate: string;
  donationId: number;
};

export type TaxEligibleDonations = {
  organizationName: string;
  taxId: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  email: string;
  totalTaxDeductibleAmount: number;
  currencyCode: string;
  getDonationCount: void;
  donations: InternalReceipts[];
};

export type DocType = 'project' | 'community' | 'organization';
export type FeaturedEntity = 'campaign' | 'organization' | 'community';
export type AdSpotType = 'general' | 'ramadan' | 'dhulhijah' | 'zakat';

export type CampaignCardRequest = {
  campaignPoolSize?: number;
  docType: DocType;
  limit: number;
  listID?: number;
  offset: number;
  randomize?: boolean;
  type?: 'assigned' | string;
  src?: string;
  closedCampaigns?: boolean;
  projectStatus?: 'team-member'; // not sure of any other accepted values
  param1?: number;
  username?: string;
  adSpotType?: AdSpotType; // optional param to show type of Campaigns from OpenSearchAPI
  /**
   * campaign id or array of campaign ids
   */
  id?: string | string[];
};

export type TaxDeductible = {
  userId: number;
  year: string;
  country: string;
  isEligible: string;
};

export type DownloadTaxStatement = {
  userId: number;
  year: string;
  country: string;
  donationGroupBy: string;
  fileType: string;
};

// Pascal case because that's how the data comes back from the API
export type CampaignCardPropsV3 = {
  ID: number;
  Name: string;
  UserID: number;
  Slug: string;
  Tags: string;
  PledgeAmount: string;
  Goal: string;
  CurrencyCode: string;
  CurrencySymbol: string;
  EndDate: string;
  ModifiedDate: string;
  Status: 0 | 1;
  Latitude: string;
  Longitude: string;
  FundingOption: 0 | 1;
  IsFeatured: 0 | 1;
  Type: string;
  URL: string;
  DaysLeft: number;
  DonationCount: number;
  Location: string;
  FundedPercentage: number;
  FundedPercentageCapped: number;
  ImageURL: string;
  UserImageURL: string;
  UserName: string;
  IsSponsored: 0 | 1;
  ZakatApproved: boolean;
  UserSupported: boolean;
  Seed: number;
  /**
   * This value is retrieved when you run the `CampaignService.cardsSupportInfo` method
   * after you've retrieved the campaign cards.
   * an example of this can be found in the `CampaignOrOrgCardBuilderComponent` component
   */
  IsFavorite: boolean;
};

/**
 * Campaign props that are returned from v4 API
 */
export type CampaignCardPropsV4 = {
  id: number;
  name: string;
  slug: string;
  tags: string;
  pledgeAmount: string;
  goal: string;
  currencyCode: string;
  currencySymbol: string;
  endDate: string;
  status: 0 | 1;
  userId: number;
  userName: string;
  userImageUrl: string;
  daysLeft: number;
  donationCount: number;
  fundedPercentage: number;
  zakatApproved: boolean;
  location: string;
  imageUrl: string;
};

export type CampaignCardProps = CampaignCardPropsV3 & CampaignCardPropsV4;

export type TrendingCampaign = {
  name: string;
  slug: string;
  currency: string;
  startDate: Date;
  endDate: Date;
  imageUrl: string;
  supporters: number;
  targetedAmount: string;
  totalRaisedAmount: string;
  address: string;
  country: string;
  city: string;
  state: string;
  creatorName: string;
  creatorImageUrl: string;
};

export enum FileType {
  'text/csv' = 'csv',
  'application/vnd.ms-excel' = 'xlsx',
  'application/pdf' = 'pdf',
}

export type Preference = {
  user_id: number;
  group_name: string;
  key: string;
  value: string;
};

export type PreferencePostRequest = {
  userId: number;
  group: string;
  key: string;
  value: string;
};

export type Card = {
  id: number;
  description: string;
  simpleDescription: string;
  giftAid: boolean;
  tokens: number;
  tokenTypes: string[];
  default: boolean;
  expired: boolean;
  expirationDate: string;
  typeEnum: string;
  address: {
    id: number;
    name: string;
    phone: string;
    address: string;
    address2: string;
    country: string;
    city: string;
    zip: string;
    state: string;
    save: boolean;
  };
  location: string;
};

export type CardsResponse = {
  cards: Card[];
  paymentDefault: string;
};

export type CardTypeLogos = {
  [key: string]: string;
  visa: string;
  mastercard: string;
  amex: string;
};

export type GivingPreference = {
  userId: number;
  value: string[];
  createdAt?: string;
  updatedAt?: string;
  id?: number;
};

export type Subscription = {
  id: number;
  userId: number;
  givingPlanId: number;
  createdAt: string;
  updatedAt: string;
  pausedAt: string | null;
  unsubscribedAt: string | null;
  properties: {
    id: number;
    subscriptionId: number;
    key: string;
    value: string;
  }[];
  givingCategories?: string[];
  donationAmount?: string;
  donationAmountCurrency?: string;
  tipAmount?: string;
  tipAmountCurrency?: string;
  cardId?: number;
  cardType?: string;
};

export type APICall = {
  loaded: boolean;
  failed: boolean;
};

export type APITracker = {
  userSession: APICall;
  subscription: APICall;
  cards: APICall;
  givingPreferences: APICall;
};

export type TaxJurisditionCountry = 'US' | 'CA';

export type CampaignCreatorAccount = {
  name: string;
  userId: number;
  accountId: string;
  subaccountId: string;
  currencyCode: string;
};

export type LatestPayout = {
  amount: number;
  date: string;
};

export type FinancialSummary = {
  grossRaisedAmount: number;
  netRaisedAmount: number;
  processingFeesAmount: number;
  pendingSettlementAmount: number;
  balanceAmount: number;
  paidAmount: number;
};

export type PayoutsMetadata = {
  took: number;
};

export type PayoutSummary = {
  id: number;
  payoutId: number;
  amount: string;
  date: string;
};

export type Payout = {
  payoutId: number;
  grossRaised: string;
  totalFees: string;
  adjustments: string;
  totalPaid: string;
  totalDonations: number;
};

export type PayoutCampaign = {
  campaignId: number;
  campaignName: string;
  campaignUrl: string;
  donationAmount: number;
};

export type SelectOption = {
  value: string;
  label: string | JSX.Element;
};

export type ListBoxOption = {
  value: string;
  label: string;
  selected: string;
};

export type DropdownItem = {
  value: string;
  label: string;
};

export type ElasticSearchParams = {
  docType: DocType;
  coordinates?: { latitude: number; longitude: number };
  limit?: number;
  offset?: number;
  almost_funded?: boolean;
  randomize?: boolean;
  src?: string;
  type?: string;
  id?: number[];
};

export type OpenSearchCategories =
  | 'food-water'
  | 'mosque-community'
  | 'education'
  | 'women'
  | 'orphans'
  | 'refugee'
  | 'emergency-relief'
  | 'health'
  | 'creative'
  | 'other';

export type OpenSearchDiscoverOptions = {
  entity: 'campaign' | 'organization' | 'community';
  authorization?: string;
  q?: string;
  sort?: 'almost_funded' | 'newest' | 'needs_love' | 'ending_soon';
  zakatVerified?: boolean;
  hideCommunityCampaigns?: boolean;
  nearMe?: string;
  countries?: string;
  charityType?: '501c3' | 'gift_aid' | 'registered_charity';
  /**
   * categories are all defined in the `OpenSearchCategories`.
   * This is of type `any` because it is returned from builder as an array
   * but it is used as a string in the API
   */
  categories?: any;
  limit?: number;
  offset?: number;
  aggregateCategories?: boolean;
  canChooseInCampaignCreation?: boolean;
  randomize?: boolean;
};

export type ElasticSearchResult = {
  filters: object;
  max_score: number;
  projects: CampaignCardProps[];
  query: object;
  total_hits: { relation: string; value: number };
};

export type UserGeolocation = {
  latitude: string;
  longitude: string;
  country: string;
  countryCode: string;
  state: string;
  region: string;
  city: string;
  timezone: string;
};

type Group = {
  Name: string;
};

type TeamMember = {
  ID: number;
  ProfileURL: string;
  ThumbnailURL: string;
  DisplayName: string;
  Facebook: string;
  Website: string;
  ProjectCount: number;
  DonationCount: number;
};

export type Community = {
  URL: string;
  ImageSrc: string;
  Name: string;
  IsSponsor: boolean;
};

type ViewSponsor = {
  ID: number;
  SponsorName: string;
  Description: null | string;
  ImageUrl: null | string;
};

export type CarouselItem = {
  IsImage: boolean;
  URL: string;
};

export type CampaignViewProps = {
  Name: string;
  URL: string;
  Tags: string;
  ID: number;
  ShortLocation: string;
  Status: string;
  EndDate: string;
  FundingOption: string;
  TaxDeductible: boolean;
  GiftAid: boolean;
  Company: null | any;
  NonprofitTypeText: string;
  TaxID: null | string;
  StripeVerified: boolean;
  CurrencyCode: Currency;
  CurrencySymbol: string;
  GoogleMapEmbedSrc: string;
  ZakahEligible: boolean;
  ZakatVerified: boolean;
  VolunteerOptIn: string;
  SocialLinks: [];
  Groups: Group[];
  Team: TeamMember[];
  Communities: Community[];
  Sponsors: ViewSponsor[];
  Carousel: CarouselItem[];
  FacebookPixelID: string | null;
};

export type DonationSponsor = {
  id: number;
  name: string;
  imageUrl: string | null;
  color: string | null;
  relativeUrl: string;
  description: string;
  slug: string;
  hasAdminForm: boolean;
};

export type Reward = {
  ID: number;
  Flags: number;
  AddedDate: string;
  Valid: boolean;
  ProjectId: number;
  Title: string;
  Description: string;
  ChallengeAmount: number;
  Limit: number;
  PrizeAwarded: number;
  PrizeAwardedDate: string | null;
  Status: number;
  ModifiedDate: string;
  NoteDescription: string;
  NoteEmail: string;
};

export type CampaignDonationProps = {
  ID: number;
  Sponsor: DonationSponsor;
  Slug: string;
  CurrencyCode: string;
  CurrencySymbol: string;
  Name: string;
  Rewards: Reward[];
  PledgeAmount: string;
  Goal: string;
  DonorCount: number;
  ProjectStatus: -2 | -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  ProjectEndDate: string;
  CurrencyCodeToDisplay: string;
  FundedPercentage: number;
  FundedPercentageCapped: number;
  ComplianceVerified: boolean;
  AuthenticityVerified: boolean;
};

export const ProjectStatus = {
  NOT_SUBMITTED: -2,
  PENDING_APPROVAL: -1,
  SUSPENDED: 0,
  ACTIVE: 1,
  COMPLETED: 2,
  FAILED: 3,
  APPROVED: 4,
  SUBMITTED_IN_REVIEW: 5,
  DIED: 6,
  REJECTED: 7,
  COMPLETE_UNPAID: 8,
};

export type CampaignAdminBarInfo = {
  faqUrl: string;
  navigation: string[];
  project: {
    ID: number;
    IsCommunityCampaign: boolean;
    canAddOfflineDonation: boolean;
    canSendUpdate: boolean;
    coaching: boolean;
    creatorName: string;
    dropdown: CampaignAdminBarDropdown[];
    golive_debug: string[];
    golive_result: string;
    is_active: boolean;
    notice: boolean;
    paymentType: string;
    slug: string;
    sponsorName: string;
    status: string;
  };
};

export const ProjectStatusText = {
  SUBMIT_FOR_REVIEW: 'Submit for Review',
  IN_REVIEW: 'In Review',
  SUSPENDED: 'Suspended',
  ACTIVE: 'Active',
  SUCCESSFUL_COMPLETED: 'Successful - Completed',
  FAILED_COMPLETED: 'Failed - Completed',
  NOT_PAID_COMPLETED: 'Not Paid - Completed',
  GO_LIVE: 'Go Live!',
  LIVE: 'Live',
  DIED: 'Died',
  REJECTED: 'Rejected',
};

export type ProjectStatus =
  | 'Submit for Review'
  | 'In Review'
  | 'Suspended'
  | 'Active'
  | 'Successful - Completed'
  | 'Failed - Completed'
  | 'Not Paid - Completed'
  | 'Go Live!'
  | 'Live'
  | 'Died'
  | 'Rejected';

export type CampaignAdminBarDropdown = {
  dialog_message: string;
  dialog_title: string;
  label: string;
  verb: string;
};

export type RequirementsCheck = {
  application: string;
  canSubmit: boolean;
  internationalFee: boolean;
  missingRequired: string[];
  preapproval: boolean;
};

export type ProjectStatePayload =
  | {
      state: 'submit-project';
      id: string;
      params: {
        DialogMsg: 'nocall';
        coaching: boolean;
        option: 'nocall';
        urgent: boolean;
      };
    }
  | {
      state: 'unsubmit-project' | 'deny-project';
      id: string;
      params: {
        reason: string;
      };
    }
  | {
      state: 'start-project';
      id: string;
    };

export type TimelineItem = {
  type: 'pitch' | 'event';
  subtype?: 'update';
  date: string;
  body: string;
  title?: string;
  id?: number;
};

export type TimelineProps = {
  items: TimelineItem[];
  editable: boolean;
  projectId: number;
};

export type Donor = {
  ID: number;
  Name: string;
  Location: string | null;
  IsAnonymous: boolean;
  IsPrivateAmount: boolean;
  Amount: string;
  CurrencyCode: string;
  CurrencySymbol: string;
  ThumbnailURL: string;
  URL: string;
  Date: string;
};

export type DonorsProps = {
  count: number;
  donors: Donor[];
};

export type ShareLinks = {
  ID: number;
  ShareTitle: string;
  ShareDescription: string;
  ShareUrl: string;
  ShareImageUrl: string;
  ShareCounter: number;
  TwitterShareUrl: string;
  ShareEmailUrl: string;
  CurrentUserLikes: boolean;
};

export type InstagramShareImages = {
  post: string;
  story: string;
};

export type GivingLevelsProps = {
  ID: number;
  ProjectID: number;
  AddedDate: moment.Moment;
  ModifiedDate: moment.Moment;
  ChallengeAmount: number;
  Title: string;
  Description: string;
  Limit: number;
  PrizeAwarded: number;
  PrizeAwardedDate: moment.Moment | null;
  Flags: number;
  IncludeEmailNote: boolean;
  RequireEmail: boolean;
  IncludeNote: boolean;
  RequireNote: boolean;
  RequireShipping: boolean;
  IsHidden: boolean;
  NoteDescription: string;
  NoteEmail: string;
  Status: number;
  Valid: boolean;
};

export type GivingLevelAdditionalInfo = {
  email?: string;
  note?: string;
  shippingAddress?: address;
};

export type CurrencyCodes = {
  code: string;
  symbol: string;
  minimumAmount: number;
  suggestedAmounts: number[];
};

export type SubscribeToNewsletterProps = {
  message: string;
  success: boolean;
};
export type RecommendedCampaignsByCategoryResponse = {
  category: string;
  response: SimpleResponse;
};

export type GivingStreamDonation = {
  campaignId: number;
  name: string;
  slug: string;
  amount: string;
  currency: string;
  firstName: string;
  lastName: string;
  isAnonymous: boolean;
  donationDate: string;
  location: string;
};

export enum OpenSearchCategoriesEnum {
  'Arts' = 'art',
  'Technology' = 'technology',
  'Causes' = 'causes',
  'Other' = 'other',
  'Education' = 'education',
  'Fashion' = 'fashion',
  'Film & Video' = 'film-video',
  'Music' = 'music',
  'Food' = 'food',
  'Publishing' = 'publishing',
  'Food/Water' = 'food-water',
  'Mosque/Community' = 'mosque-community',
  'Women' = 'women',
  'Orphans' = 'orphans',
  'Refugee' = 'refugee',
  'Emergency Relief' = 'emergency-relief',
  'Health' = 'health',
  'Creative' = 'creative',
}

export enum DBCategoryToHumanReadable {
  'Arts' = 'the arts',
  'Technology' = 'technology',
  'Causes' = 'other causes',
  'Other' = 'other causes',
  'Education' = 'education',
  'Fashion' = 'fashion',
  'Film & Video' = 'film and video projects',
  'Music' = 'music',
  'Food' = 'food',
  'Publishing' = 'publishing',
  'Food/Water' = 'food and water',
  'Mosque/Community' = 'mosque and communities',
  'Women' = 'women',
  'Orphans' = 'orphans',
  'Refugee' = 'refugee',
  'Emergency Relief' = 'emergency relief',
  'Health' = 'health',
  'Creative' = 'creative projects',
}

/**
 * Competition Types
 */

export enum LeaderboardType {
  FUNDS_RAISED = 'funds-raised',
  MOST_SUPPORTERS = 'most-supporters',
}

export enum ParticipantType {
  CAMPAIGN = 'CAMPAIGN',
  DONOR = 'DONOR',
}

export enum CompetitionStatus {
  SYNCING = 'SYNCING',
  SYNCED = 'SYNCED',
  COMPLETED = 'COMPLETED',
}

export type Competition = {
  id: number;
  name: string;
  participantType: ParticipantType;
  locationType: LocationType;
  startAt: string;
  endAt: string;
  currencyCode: string;
  communityIds: number[];
  status: CompetitionStatus;
  createdAt: Date;
  updatedAt: Date;
  ranks: Rank[];
  group?: CompetitionGroupInformation;
};

export type CompetitionGroupInformation = {
  id: number;
  name: string;
  next?: {
    id: number;
    order: number;
  };
  previous?: {
    id: number;
    order: number;
  };
  order: number;
};

export type EntityStat = {
  entityId: number;
  total: {
    raw: number;
    formatted: string;
  };
  count: number;
  position?: number;
  winnings?: {
    raw: number;
    formatted: string;
  };
  accountId?: number;
};

export type CompetitionEntityStats = {
  competition: Competition;
  stats: EntityStat[];
  total: number;
};

export type Rank = {
  id: number;
  competitionId: number;
  order: number;
  description: string;
  incentives: Incentive[];
  createdAt: string;
  updatedAt: string;
};

export type Incentive = {
  id: number;
  competitionRankId: number;
  description: string;
  amount: number;
  max: number;
  percent: number;
  type: IncentiveType;
  createdAt: string;
  updatedAt: string;
};

export enum IncentiveType {
  FixedCash = 'FIXED_CASH',
  Percent = 'PERCENT',
}

export interface CampaignEntity {
  ID: number;
  Name: string;
  UserID: number;
  Slug: string;
  Tags: string;
  PledgeAmount: number;
  Goal: number;
  CurrencyCode: string;
  EndDate: string;
  ModifiedDate: number;
  Status: number;
  FundingOption: number;
  IsFeatured: boolean;
  CurrencySymbol: string;
  DonationCount: number;
  Location: string;
  FundedPercentage: number;
  FundedPercentageCapped: number;
  ImageURL: string;
  UserImageURL: string;
  UserName: string;
  IsSponsored: boolean;
  SponsorBannerURL: string;
  SponsorName: string;
  SponsorBannerClass: any;
  CountryID: string;
  URL: string;
  ZakahEligible: boolean;
  ZakatApproved: boolean;
  Categories: string[];
  Type: string;
  UserSupported: boolean;
  DaysLeft: number;
  Latitude: string;
  Longitude: string;
}

export type CompetitionRankedStats = {
  entity?: CampaignEntity;
  stats: EntityStat;
  rank?: Rank;
};

export type V3ApiReturnProps = {
  success: boolean;
  message: string;
  result: any;
};

export type SubscriptionObject = {
  id?: number;
  subscriptionStatus?: string;
  givingPlanId: number;
  donationAmount: number;
  donationAmountCurrency: string;
  donationAmountCurrencySymbol?: string;
  tipAmount: number;
  tipAmountCurrency: string;
  tipAmountCurrencySymbol?: string;
  givingCategories?: string[];
  cardId: number;
  cardType?: string;
  cardDescription?: string;
  referralCode?: string;
  referralSource?: string;
  additionalDonationAmount?: number;
  additionalTipAmount?: number;
  unsubscribedAt?: string | null;
  subscribedOn?: string;
  pausedAt?: string | null;
  enabled?: boolean;
  intendedGivingPlanId?: number;
  giftAid?: boolean;
  marketingFlag?: boolean;
  taxReceipt?: boolean;
  timeToNextCharge?: string | null;
  rewardId?: number;
};

export type SubscriptionRawResponse = {
  id: number;
  cardId: number;
  cardType: string;
  createdAt: string;
  donationAmount: string;
  donationAmountCurrency: string;
  enabled: boolean;
  givingCategories: string[];
  givingPlanId: number;
  lastExecutedAt?: string | null;
  legacySubscriptionId?: number | null;
  pausedAt?: string | null;
  source?: string | null;
  timeToNextCharge?: string | null;
  tipAmount?: string | null;
  tipAmountCurrency?: string | null;
  unsubscribedAt?: string | null;
  updatedAt: string;
  userId: number;
  properties: {
    [key: string]: string | number;
  };
};

//the IDs are of type string[] because they are being stored in place of Giving Categories in the Subscription Provider (which is an array of strings)
export interface GivingListObject {
  givingListCampaignIds: string[];
  success: boolean;
}

export type OpenSearchCampaignResponse = {
  max_score: number;
  paging: OpenSearchCampaignResponsePaging;
  results: OpenSearchCampaignResponseResult[];
  query_body?: OpenSearchCampaignResponseQueryBody;
};

type OpenSearchCampaignResponsePaging = {
  total: number;
  limit: number;
  offset: number;
};

export type OpenSearchCampaignResponseResult = {
  ID: number;
  Name: string;
  UserID: number;
  Slug: string;
  Tags: string;
  PledgeAmount: number;
  Goal: number;
  CurrencyCode: string;
  EndDate: string;
  ModifiedDate: number;
  Status: number;
  FundingOption: number;
  IsFeatured: boolean;
  CurrencySymbol: string;
  DonationCount: number;
  Location: string;
  FundedPercentage: number;
  FundedPercentageCapped: number;
  ImageURL: string;
  UserImageURL: string;
  UserName: string;
  IsSponsored: boolean;
  SponsorBannerURL: string;
  SponsorName: string;
  SponsorBannerClass: null;
  CountryID: string;
  URL: string;
  ZakahEligible: boolean;
  ZakatApproved: boolean;
  Categories: string[];
  Type: string;
  UserSupported: boolean;
  DaysLeft: number;
  Latitude: null;
  Longitude: null;
};

type OpenSearchCampaignResponseQueryBody = {
  index: string;
  format: string;
  urlQuery: string;
  queryBody: {
    from: number;
    size: number;
    query: {
      bool: {
        must: Array<{ term: { [key: string]: string | number } }>;
        should: any[];
        filter: any[];
      };
    };
    aggs: any;
    sort: any[];
  };
};

export type QurbaniReward = {
  Type: string;
  ID: number;
  Name: string;
  ProjectID: number;
  Description: string;
  Price: number;
  Currency: string;
  Limit: number;
  CountClaimed: number;
  NoteDescription: string | null;
  NoteEmail: string | null;
  RequireShipping: boolean;
  IncludeNote: boolean;
  RequireNote: boolean;
  IncludeEmailNote: boolean;
  Hidden: boolean;
  Animal: string;
  AccountID: number;
  CommunityID: number;
  Countries: string[];
  SettlementCurrency: string;
  Active: boolean;
  ProjectName: string;
  ProjectRelativeURL: string;
  ProjectLogoURL: string;
  CommunityName: string;
  CommunityRelativeURL: string;
  CommunityLogoURL: string;
  UserID: number;
  UserName: string;
  UserImageURL: string;
  TotalAmount: number;
  USDPrice: number;
  ZakatVerified: boolean;
};

export type UserWithReward = {
  ID: number;
  Name: string;
  Count: number;
};

export type OpenSearchRewardsResponse = {
  max_score: number;
  paging: OpenSearchCampaignResponsePaging;
  results: QurbaniReward[];
  query_body?: OpenSearchCampaignResponseQueryBody;
};

export type OpenSearchUserRewardsResponse = {
  max_score: number;
  paging: OpenSearchCampaignResponsePaging;
  results: UserWithReward[];
  query_body?: OpenSearchCampaignResponseQueryBody;
};

export interface CampaignBasics {
  id: number;
  slug: string;
  userId: number;
  name: string;
  imageUrl: string;
  campaignCreatorName?: string;
}

export type CampaignStatus = {
  buttonText: string;
  enabled: boolean;
  id: number;
  favorited: boolean;
  allowFavorite: boolean;
  supported?: boolean;
};

export enum Intervals {
  HOUR = 'hours',
  DAY = 'days',
  WEEK = 'weeks',
  MONTH = 'months',
  YEAR = 'years',
}

// snake case because that's how the data comes back from the API
export type AllTimeStats = {
  total_funded: string;
  total_campaigns: number;
  total_countries: number;
  total_users: number;
};

export type PasswordAnalysis = {
  length: number;
  haveLowerCase: boolean;
  haveNumber: boolean;
  haveSpecialChar: boolean;
  haveUpperCase: boolean;
  haveMinLength: boolean;
  haveAll: boolean;
  score: number;
};

type Stats = {
  donation: {
    totalAmount: number;
    totalCurrencySymbol: string;
    totalCurrencyCode: string;
    supportedCampaigns: number;
  };
  general: {
    memberCount: number;
    newMembers: number;
    periodDonationAmount: number;
    periodName: string;
    newUserPeriodSuffix: string;
    periodAbbreviation: string;
    citiesCount: number;
    countriesCount: number;
    facebookVoteGroupID: string;
    callToAction: string;
    suggestedAmounts: number[];
  };
};

type FaqItem = {
  title: string;
  body: string;
};

type Faq = {
  items: FaqItem[];
  contact: {
    title: string;
    email: string;
    emailLink: string;
  };
};

export type UserLoginResponse = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  token: string;
};

export type campaignAdminBasicInfoResponse = {
  slug?: string;
  tabs?: Array<{
    id?: string;
    status?: string;
  }>;
  application?: string;
  payment_type?: string;
  account_id?: number;
  canEdit?: boolean;
  /**
   * Campaign is accessible to anyone with the link, but will not show up in LaunchGood's search results
   */
  isCampaignHide: boolean;
  /**
   * Campaign is only accessible to admins and will not be visible to the campaign creator or the public.
   */
  isCampaignHideForNonAdmin: boolean;
};

export type registerUserRequest = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  receiveUpdates: boolean;
  euMember?: boolean;
  countryCode?: string;
};

export type ReferredOnboarded = {
  id: number;
  firstName: string;
  lastName: string;
  profileUrl: string;
  email: string;
  thumbnailUrl: string;
};

export type ChallengeGetGivingMemberStatsResult = {
  referredUserCount: number;
  totalDaysDonated: number;
  points: number;
  challengeCounts: number;
  entries: GivingMemberEntry[];
  alternates: GivingMemberAlternates;
  userStats: GivingMemberUserStats;
};

export type GivingMemberEntry = {
  DayIndex: number;
  Label: string;
  SponsoredProjectID: number[] | number;
  Donated: boolean;
  DonatedProjectID?: number[] | number;
};

export type GivingMemberAlternates = {
  project_ids: number[][];
  give_list: number[];
};

export type GivingMemberUserStats = {
  TotalRaisedBySupportedCampaigns: number;
};

// Casing is capitalized because that's how the API expects it

export type BillingAddress = {
  Name?: string;
  Address?: string;
  Address2?: string;
  State?: string;
  City?: string;
  Zip?: string;
  Country?: string;
  Save?: boolean;
};

export type GuestUser = {
  id: number;
  type: string;
  hash: string;
};

export enum LgMerchantAccounts {
  CAD = 'LaunchGood_CAD',
  GBP = 'LaunchGood_GBP',
  USD = 'LaunchGoodIncECOM',
}

export type TransactionFees = {
  TransactionUnit: number;
  TransactionRate: number;
  ApplicationRate: number;
  ApplicationUnit: number;
  OfflineApplicationRate: number;
  OfflineApplicationUnit: number;
  Version: number;
};

export type ProjectDonationMetadata = {
  ID: number;
  Slug: string;
  URL: string;
  CurrencyCode: string;
  CurrencySymbol: string;
  Name: string;
  RestrictedToCardTypes: string[];
  Rewards: GivingLevelsProps[];
  IsElegibleForSharingAddress: boolean;
  Fees: TransactionFees;
  GiftAid: boolean;
  ZakahEligible: number;
  HideHelping: boolean;
  MinimumDonationAmount: number;
  SettlementCurrency: string;
};

export type DonorDetails = {
  name: string;
  location: string;
  displayImg: string;
  customName: boolean;
  anonymous: boolean;
};

export const LocaleToCurrency: Record<string, Currency> = {
  en: 'USD',
  'en-us': 'USD',
  'es-ec': 'USD',
  'es-sv': 'USD',
  'es-pa': 'USD',
  'en-ca': 'CAD',
  'fr-ca': 'CAD',
  'en-sg': 'SGD',
  'zh-sg': 'SGD',
  'en-au': 'AUD',
  'en-nz': 'NZD',
  'ms-my': 'MYR',
  'en-gb': 'GBP',
  'lv-lv': 'EUR',
  lv: 'EUR',
  'de-at': 'EUR',
  'nl-be': 'EUR',
  'fr-be': 'EUR',
  'el-cy': 'EUR',
  'et-ee': 'EUR',
  et: 'EUR',
  'fi-fi': 'EUR',
  'fr-fr': 'EUR',
  fr: 'EUR',
  'de-de': 'EUR',
  'el-gr': 'EUR',
  'en-ie': 'EUR',
  'it-it': 'EUR',
  'lt-lt': 'EUR',
  'de-lu': 'EUR',
  'sr-me': 'EUR',
  'nl-nl': 'EUR',
  'pt-pt': 'EUR',
  'sk-sk': 'EUR',
  'sl-sl': 'EUR',
  'es-es': 'EUR',
  'de-sh': 'EUR',
};

// snake case because that's how the data comes back from the API

export type ReportAction = {
  identifier: string;
  verb: string;
  format: string;
};

export type ReportV4Request = {
  account_id?: string;
  type: string;
  start?: string;
  end?: string;
  year?: string;
  status?: string;
  fmt: string;
  admin_view?: number;
};

export type ReportV4RequestByYear = {
  account_id: number;
  type: string;
  year: string;
  fmt: string;
  admin_view?: number;
};

export interface ReportMeta {
  format: string;
  identifier: string;
  label: string;
}

// snake case because that's how the data comes back from the API
export type GetUserByTokenResponse = {
  ID: number;
  Valid: true;
  Email: string;
  GroupID: number;
  AffinityGroupID: number;
  IpAddress: string;
  Active: number;
  ActivationCode: null | string;
  CreatedOn: string;
  LastLogin: string;
  Username: string;
  Flags: number;
  DeletedAt: null | string;
  GoogleUserID: null | string;
  GoogleAccessToken: null | string;
  GoogleAccessTokenCreatedDate: null | string;
  GoogleAccessTokenExpiration: null | string;
};

export type SetPasswordResponse = {
  id: number;
  token: string;
};

export interface InputCurrencies {
  code: string;
  symbol: string;
  min: number;
  placeholder: number;
  presetAmounts?: number[];
  referralMinimum?: number;
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  PAUSED = 'paused',
  DELETED = 'deleted',
  UNKNOWN = 'unknown response',
  INACTIVE = 'not subscribed',
}

export interface OptionsObject {
  subheading: string;
  description: string;
  challengeId?: number;
  imageUrl?: string;
}

export type Country = {
  Code: string;
  Name: string;
};

export interface ChooseCausesProps {
  minRequiredCauses?: number;
  selectedCauses: string[];
}

export interface ErrorBoxProps {
  message: string;
}

export type Comment = {
  id: string;
  createdAt: string | number | Date;
  campaignId: number;
  comment: string;
  userName: string;
  userLocation: string;
  isHidden: boolean;
  isDeleted: boolean;
};

export type ComboBoxItem = {
  value: string;
  name: string;
};

export interface GetReferralsByAccountId {
  campaignId: number;
  refererCode: string;
  qualifiedReferrals: number;
  payoutValue: number;
  payoutValueCurrency: string;
}

export interface ReferralsSubStats {
  totalPayoutValue: number;
  totalPayoutValueCurrency: string;
}

export interface GetFinancialAnalytics {
  grossRaised: number;
  analytics: GraphAnalytics[];
}

export interface GraphAnalytics {
  label: string;
  dataSets: {
    grossRaised: number;
  }[];
}

export enum QurbaniAnimal {
  COW = 'cow',
  SHEEP = 'sheep',
  GOAT = 'goat',
  CAMEL = 'camel',
}

export type CampaignComment = {
  id: number;
  campaignId: number;
  donationId: number;
  comment: string;
  userDisplayName: string;
  userDisplayLocation: string;
  isHidden: boolean;
  isDeleted: boolean;
  createdAt: Date;
  updatedAt?: Date;
};

export type CampaignCommentsResponse = {
  donationComments: CampaignComment[];
  limit: number;
  offset: number;
  totalRecords: number;
};

// Recurring Giving Types
export type RecurringGivingPlanType = 'monthly' | 'weekly';
export type GivingPlanCalendarType = 'gregorian' | 'hijri';
export type GivingPlanCategoryType = 'CHARITY' | 'INTERNAL';
export interface GivingPlanWithProperties {
  id: number;
  name: string;
  type: GivingPlanCalendarType;
  legacyMetadata?: any;
  createdByUserId: number;
  createdAt: Date;
  updatedAt: Date;
  archivedAt: null;
  deletedAt: null;
  campaignId?: number;
  category: GivingPlanCategoryType;
  properties: GivingPlanProperties;
  metadata?: GivingPlanMetadata;
  signedUp?: number;
}

export interface GivingPlanProperties {
  frequency: string;
  gregorianDayStart: number;
  interval: number;
  chargeTimeValue: string;
  chargeTimeType: string;
  byDay:
    | 'Sunday'
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday';
  amountSelectorType?: GivingPlanAmountSelectorType;
  signupTrackerProgressMax?: number;
  defaultDonationAmount?: number;
  showProjectDescription?: boolean;
}

export enum GivingPlanAmountSelectorType {
  GIVING_LEVELS = 'giving levels',
  DEFAULT = 'default',
}

export enum RewardType {
  level,
  qurbani,
}

export interface Rewards {
  rewardId: number;
  projectId: number;
  rewardTitle: string;
  rewardDescription: string;
  rewardChallengeAmount: number;
  rewardLimit: number;
  prizeAwarded: number;
  prizeAwardedDate?: moment.Moment | null;
  rewardStatus: number;
  addeddate: moment.Moment;
  modifieddate: moment.Moment;
  flags: number;
  noteDescription?: string;
  noteEmail?: string;
  rewardType: RewardType;
  animal?: QurbaniAnimal;
  accountId?: number;
  countries?: string;
  deletedAt?: moment.Moment;
  totalAmount: number;
}

export interface GivingPlanMetadata {
  campaign?: {
    id: number;
    name: string;
    slug: string;
    imageUrl?: string;
    nonprofitType?: string;
    nonprofitTypeText?: string;
    tags?: string;
    description?: string;
    giftAid?: boolean;
    currencyCode?: Currency;
    taxId?: string;
    currency?: string;
    settlementCurrency?: string;
  };
  user?: {
    id: bigint;
    name: string;
    userImageUrl?: string;
  };
  account?: {
    id: number;
    name: string;
    taxId?: string;
    authenticityVerified?: boolean;
    taxDeductible?: boolean;
  };
  rewards: Rewards[];
}

export type CurrencyFormattedAmount = {
  raw: number;
  formatted: string;
};

export type SgSubscription = {
  id: number;
  userId: number;
  givingPlanId: number;
  createdAt: string;
  updatedAt: string;
  pausedAt: string | null;
  unsubscribedAt: Date | null;
  lastExecutedAt: Date | null;
  properties?: { [key: string]: any };
  givingCategories?: string[];
  donationAmount?: number;
  donationAmountCurrency?: string;
  tipAmount?: number;
  tipAmountCurrency?: string;
  cardId?: number;
  cardType?: string;
  source?: string;
  legacySubscriptionId?: number;
  timeToNextCharge?: string;
  enabled: boolean;
  taxReceipt?: boolean;
  marketingOptIn?: boolean;
  giftAid?: boolean;
  givingPlan?: GivingPlanWithProperties;
  campaign?: SgCampaignInfo;
};

export type SgCampaignInfo = {
  id: number;
  name: string;
  slug: string;
  image?: string;
};

export type SubscriptionBillingEvent = {
  id: number;
  givingPlanId: number;
  subscriptionId: number;
  bulkBillingEventId: number;
  email: string;
  donationId?: number;
  donationAmount: number;
  donationAmountCurrency: Currency;
  tipAmount: number;
  tipAmountCurrency: Currency;
  donationValue?: number;
  donationValueCurrency?: Currency;
  tipValue?: number;
  tipValueCurrency?: Currency;
  cardId: number;
  cardType: string;
  cardDescription?: string;
  givingCategories?: string[];
  campaignId?: any;
  createdAt: string;
  updatedAt: string;
  scheduledAt: string;
  status: SubscriptionBillingEventStatus;
  userBillingRequest?: any;
  userBillingResponse?: any;
  retryForId?: number;
  campaign?: SgCampaignInfo;
};

export enum SubscriptionBillingEventStatus {
  SCHEDULED = 'SCHEDULED',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
  CANCELLED = 'CANCELLED',
}

export interface SubscriptionBillingEventResponse {
  billingEvents: SubscriptionBillingEvent[];
  pagination: {
    totalCount: number;
    hasMore: boolean;
    nextSkip: number | null;
  };
}

export interface CharitySearchResult {
  id: number;
  name: string;
  description?: string;
  slug: string;
  imageUrl?: string;
  isAuthenticated?: boolean;
  nonprofitTypeText?: string;
  taxId?: string | null;
  plans?: GivingPlanWithProperties[];
  signedUp?: number;
}
export type PlanInfo = {
  id: number;
  name: string;
  startDate: string;
  endDate: string;
  givingPlanDay: number;
  daysToGivingPlan: number;
  signedUp: number;
  newMembers: number;
};

export type CampaignContributionsResponse = {
  DonationSum: {
    [key: string]: number;
  };
  RewardCount: number;
  Rewards: {
    ID: number;
    Title: string;
  }[];
  DonationCount: number;
};
